module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'BAUSCHKE BRAEUER BUHLMANN', // Navigation and Site Title
  titleAlt: 'BAUSCHKE BRAEUER BUHLMANN', // Title for JSONLD
  description:
    'Bauschke Braeuer Buhlmann ist eine auf das Kunstrecht spezialisierte Anwaltssozietät. Zu unseren Mandanten zählen international etablierte Künstler, Galerien, Kunsthändler, Kunstinstitutionen, Finanzinstitute und andere Akteure des Kunstmarktes. Zu unseren Mandanten zählen international etablierte Künstler, Galerien, Kunsthändler, Kunstinstitutionen, Finanzinstitute und andere Akteure des Kunstmarktes. Aufgrund unserer engen Vertrautheit mit den Usancen des internationalen Kunstmarktes arbeiten wir diskret, lösungsorientiert und effizient.',
  headline: 'BAUSCHKE BRAEUER BUHLMANN', // Headline for schema.org JSONLD
  url: 'https://bauschkebraeuer.com', // Domain of your site. No trailing slash!
  logo: '/logos/logo-1024.png', // Used for SEO
  ogLanguage: 'de_DE', // Facebook Language

  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: '', // shortname for manifest. MUST be shorter than 12 characters
  author: 'BAUSCHKE BRAEUER BUHLMANN', // Author for schemaORGJSONLD
  themeColor: '',
  backgroundColor: '',

  twitter: '', // Twitter Username
  facebook: '', // Facebook Site Name
  skipNavId: 'reach-skip-nav' // ID for the "Skip to content" a11y feature
}
