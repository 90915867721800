module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BAUSCHKE BRAEUER BUHLMANN","short_name":"BAUSCHKE BRAEUER BUHLMANN","description":"Bauschke Braeuer Buhlmann ist eine auf das Kunstrecht spezialisierte Anwaltssozietät. Zu unseren Mandanten zählen international etablierte Künstler, Galerien, Kunsthändler, Kunstinstitutionen, Finanzinstitute und andere Akteure des Kunstmarktes. Zu unseren Mandanten zählen international etablierte Künstler, Galerien, Kunsthändler, Kunstinstitutionen, Finanzinstitute und andere Akteure des Kunstmarktes. Aufgrund unserer engen Vertrautheit mit den Usancen des internationalen Kunstmarktes arbeiten wir diskret, lösungsorientiert und effizient.","start_url":"","background_color":"","theme_color":"","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3869800cdd66932e3e52e5ceeb88a023"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
