import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LocaleContext } from '../components/Layout'

const ImprintPrivacyOverlay = ({ locale }) => {
  const langIndex = locale === 'en-gb' ? 1 : 0
  const data = useStaticQuery(graphql`
    query ImprintPrivacyOverlay {
      allPrismicImprintAndPrivacy {
        edges {
          node {
            data {
              imprint {
                html
              }
              privacy_policy {
                raw {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const imprintContent = data.allPrismicImprintAndPrivacy.edges[langIndex].node.data.imprint.html
  const privacyContent =
    data.allPrismicImprintAndPrivacy.edges[langIndex].node.data.privacy_policy.raw[0].text

  return (
    <div className="imprint-privacy-overlay columns is-gapless is-marginless">
      <div className="imprint-privacy-overlay__imprint column is-half">
        <div dangerouslySetInnerHTML={{ __html: imprintContent }} />
      </div>

      <div className="imprint-privacy-overlay__privacy column is-half">
        <div className="imprint-privacy-overlay__privacy-container">
          <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
        </div>
      </div>
    </div>
  )
}
export default ImprintPrivacyOverlay
