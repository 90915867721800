import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { LocaleContext } from '../components/Layout'
import LogoBlack from '../img/bb-logo-black.svg'
import LogoBlackShort from '../img/bb-logo-black--short.svg'
import Modal from 'react-modal'
import ImprintPrivacyOverlay from '../components/ImprintPrivacyOverlay'
import modalStyle from '../styles/modal-style'
import Close from '../img/close.svg'

const Navbar = () => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  useEffect(() => {
    Modal.setAppElement('body')
  })

  const [imprintModalOpen, setImprintModalOpen] = React.useState(false)

  const openImprintModal = () => {
    setImprintModalOpen(true)
  }

  const closeImprintModal = () => {
    setImprintModalOpen(false)
  }

  if (typeof window !== 'undefined') {
    return (
      <nav className="navbar is-fixed-top is-transparent">
        <div className="navbar__inner">
          <div className="navbar__section navbar__section--anchors">
            <div className="navbar__link navbar__link--expertise">
              <a href={`#${i18n.expertiseAnchor}`}>{`${i18n.expertise}`}</a>
            </div>
            <div className="navbar__link navbar__link--lawyers">
              <a href={`#${i18n.lawyersAnchor}`}>{`${i18n.lawyers}`}</a>
            </div>
            <div className="navbar__link navbar__link--contact">
              <a href={`#${i18n.contactAnchor}`}>{`${i18n.contact}`}</a>
            </div>
          </div>
          <div className="navbar__section navbar__section--logo">
            <a href="#intro">
              <LogoBlack className="bbb_logo navbar__logo" />
              <LogoBlackShort className="bbb_logo--short navbar__logo" />
            </a>
          </div>
          <div className="navbar__section">
            <div
              className={`navbar__link navbar__link--locales ${
                lang.locale === 'de-de' ? 'is-de-in' : 'is-en-in'
              }`}
            >
              <div className="navbar__locale-switcher" data-name="locale-switcher">
                {lang.locale === 'de-de' ? (
                  <Link className="locale-link locale-link--de" hrefLang="de-de" to="/en">
                    <span className="de">de</span>
                    <span className="slash is-faded">/</span>
                    <span className="en is-faded">en</span>
                  </Link>
                ) : (
                  <Link className="locale-link locale-link--en" hrefLang="en-gb" to="/">
                    <span className="de is-faded">de</span>
                    <span className="slash is-faded">/</span>
                    <span className="en">en</span>
                  </Link>
                )}
              </div>
            </div>
            <div className="navbar__link navbar__link--imprint-privacy">
              <a onClick={openImprintModal}>{`${i18n.imprintPrivacy}`}</a>
              <Modal
                isOpen={imprintModalOpen}
                onRequestClose={closeImprintModal}
                contentLabel={`${i18n.imprintPrivacy}`}
                style={modalStyle}
                closeTimeoutMS={400}
              >
                <a className="overlay-close" onClick={closeImprintModal}>
                  <Close />
                </a>
                <ImprintPrivacyOverlay locale={lang.locale} />
              </Modal>
            </div>
          </div>
        </div>
      </nav>
    )
  } else {
    return <div></div>
  }
}

export default Navbar
