const website = require('./website')

module.exports = {
  'de-de': {
    default: true,
    path: 'de',
    locale: 'de-de',
    siteLanguage: 'de',
    ogLang: 'de_DE',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: 'Bauschke Braeuer Buhlmann',
    category: 'Kategorie',
    categories: 'Kategorien',
    was: 'wurde',
    were: 'wurden',
    tagged: 'markiert mit',
    recent: 'Neue',
    projects: 'Projekte',
    allCategories: 'Alle Kategorien',
    entries: 'Einträge',
    expertise: 'Kanzlei',
    expertiseAnchor: 'kanzlei',
    lawyers: 'Rechtsanwälte',
    lawyersAnchor: 'rechtsanwaelte',
    contact: 'Kontakt',
    contactAnchor: 'kontakt',
    imprintPrivacy: 'Impressum/Datenschutz'
  },
  'en-gb': {
    path: 'en',
    locale: 'en-gb',
    siteLanguage: 'en',
    ogLang: 'en_US',
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription:
      'Bauschke Braeuer Buhlmann is a Berlin based art law firm. We work with internationally renowned artists, galleries, art dealers, art institutions, financial institutions providing art-backed loans and other protagonists on the art market. We provide legal advice, draft and negotiate contracts and represent our clients in litigation. In international matters, we collaborate with law firms and specialized tax consultants abroad. Due to our profound knowledge of the practices of the international art market we work solution- orientated, efficiently and discreetly.',
    headline: website.headline,
    category: 'Category',
    categories: 'Categories',
    was: 'was',
    were: 'were',
    tagged: 'tagged with',
    recent: 'Recent',
    projects: 'projects',
    allCategories: 'All categories',
    entries: 'entries',
    expertise: 'Expertise',
    expertiseAnchor: 'expertise',
    lawyers: 'Lawyers',
    lawyersAnchor: 'lawyers',
    contact: 'Contact',
    contactAnchor: 'contact',
    imprintPrivacy: 'Imprint/Privacy'
  }
}
