/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Navbar from '../components/Navbar'
import '../styles/styles.scss'
import i18n from '../../config/i18n'

const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale } }) => {
  const data = useStaticQuery(query)

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <>
        <Navbar />
        {children}
      </>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }

const query = graphql`
  query LayoutQuery {
    homepage: allPrismicHomepage {
      edges {
        node {
          lang
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
