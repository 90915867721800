const modalStyle = {
  overlay: {
    backgroundColor: null,
  },
  content: {
    top: null,
    left: null,
    right: null,
    bottom: null,
    border: null,
    background: '#fff',
    borderRadius: null,
    padding: null,
    position: null,
  },
}

export default modalStyle
